@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@responsive {
  .shadow-top-md {
    -webkit-box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
    box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
  }

  .shadow-bottom-md {
    -webkit-box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
    box-shadow: 0px -8px 15px -1px rgba(0,0,0,0.10);
  }

  .inner-shadow-right-md {
    -moz-box-shadow:    inset 0 0 10px #000000;
    -webkit-box-shadow: inset 0 0 10px #000000;
    box-shadow:         inset 0 0 10px #000000;
  }

  .text-shadow {
    text-shadow: 0 2px 4px rgba(0,0,0,0.10);
  }

  .text-shadow-heavy {
    text-shadow: 0 2px 4px rgba(0,0,0,0.60);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0,0,0,0.12), 0 2px 4px rgba(0,0,0,0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0,0,0,0.11), 0 5px 15px rgba(0,0,0,0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }

  /*  .no-scrollbar {*/
  /*&::-webkit-scrollbar {*/
  /*   display: none;*/
  /* }*/
  /*}*/
}
